import { fetchController } from "./FetchController/fetchController";

export const addIntentsToAssistant = async (
  getAssistantData,
  defaultIntents,
  emails
) => {
  const url =  getHostDomain();;

  const assistantId = getAssistantData.data.id;

  // Use Promise.all to wait for all asynchronous operations to complete
  const promises = defaultIntents.map(async (intent) => {
    const matchingEmail = emails.find((email) => email.name === intent.name);

    if (matchingEmail) {
      const endpoint = `/api/v1/assistant/${assistantId}/add_intent/${intent.id}`;
      const urlHit = url + endpoint;
      const body = {
        actions: [
          {
            type: "email",
            email: matchingEmail.email,
            emailName: matchingEmail.name.toUpperCase(),
          },
        ],
      };

      try {
        const getData = await fetchController(urlHit, "POST", body);
        return getData;
      } catch (error) {
        throw error; // Propagate the error to the Promise.all catch block
      }
    } else {
      console.warn(`Email not found for intent: ${intent.name}`);
    }
  });

  try {
    const results = await Promise.all(promises);
    return results;
  } catch (error) {
    console.error("Error in one or more API requests:", error);
    throw error;
  }
};
export const isValidURL = (url) => {
  const regex =
    /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?(localhost|(?:[^\s.]+[.](?:[a-z]{2,}|co\.uk|org\.in)))(?::\d+)?(?:[/?]\S*)?$/i;

  return regex.test(url);
};

export const clipText = (text, length = 20) => {
	if (text.length > length) {
		const slicedText = text.slice(0, length);
		return `${slicedText} ...`;
	}

	return text;
};

export const agencyBillingPlanGroupENUM = Object.freeze({
  regular: 'Regular',
  addons: 'Addon',
  hidden: 'Hidden',
});

export const getHostDomain = () => {
  let apiUrl = `https://${localStorage.getItem("API_SERVER_URL")}`
  if (!apiUrl) {
    window.location.href = "/login"
  }
  return apiUrl
}

export const validateRegex = (pattern, inputValue) => {
  const regex = new RegExp(pattern);
  return regex.test(inputValue);
}


export const getAbsLogoUrl=(logo)=>{
  if(!logo) return null
  // if(logo.includes(window.origin) || logo.includes("insighto")) return logo  //keep uncomment for local
  if(logo.includes(window.origin)) return logo   // for prod
  return `${window.location.origin}${logo}`
}
